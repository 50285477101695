import Main from "pages/main/Main";
import Company from "pages/member/Company";
import Login from "pages/member/Login";
import PwSetting from "pages/member/PwSetting";
import PublicOnly from "pages/private/publicOnly";
import SadadreamList from "pages/sadadream/List";
import React from "react";
import { Navigate } from "react-router-dom";

//type
//login : 로그인 한정 페이지
// notLogin : 비로그인 한정 페이지
// null : 로그인,비로그인 무관 페이지

export const routes = [
    {
        exact: true,
        path: "/pageErr",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/pageErr/:err",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/",
        component: <Navigate to="/main" />,
        type : null
    },
    {
        exact: true,
        path: "/main",
        component: <Main/>,
        type : null
    },
    {
        exact: true,
        path: "/sada-dream",
        component: <SadadreamList/>,
        type : null
    },
    
];