import { useNavigate } from "react-router-dom";
import socketio from "socket.io-client"
import Echo from "laravel-echo";
import { useEffect, useRef, useState } from "react";
import useGet from "api/useGet";
import { comFormat, moveScroll } from "js/function";

function FloatingMenuBox(data){
    return (
        <div className={`floatingMenuBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function FloatingMenuItem(data){
    return (
        <button type="button" onClick={()=>{
            if(data.type == "up"){
                moveScroll("body",0);
            }else{
                data.func();
            }
        }} className={`floatingMenuItem ${data.addClass ? data.addClass : ""}`} style={data.bgColor ? {backgroundColor:data.bgColor} : null}>
            <img src={data.img}/>
        </button>
    );
}

//footer 메뉴
function FooterManu(data){
    const navigate = useNavigate();

    const [dataUpdate,setDataUpdate] = useState(1);
    const closeCount = useRef(null);

    const countApi = useGet({
        url:dataUpdate ? `/dreamtalk/unread/message?update=${dataUpdate}` : null,
        loginType:"login"
    });
    const countData = countApi?.data?.count;

    function countUpdate(){
        setDataUpdate(dataUpdate + 1);
        setTimeout(() => {
            setDataUpdate(null);
        }, 100);
    }

    useEffect(() => {
        if(!window.Echo){
            window.Echo = new Echo({
                host: process.env.REACT_APP_SOCKET_URL, 
                broadcaster: "socket.io",
                client: socketio
            });
        }

        window.Echo.channel("laravel_database_chat").listen("MessagePushed", (e) => {
            if(closeCount.current == null){
                function timerChk(duration) {
                    let timerNumber = duration;
                    closeCount.current = setInterval(function() {
                        if (--timerNumber < 0) {
                            timerNumber = 0;
                            clearInterval(closeCount.current);
                            closeCount.current = null;

                            countUpdate();
                        }
                    }, 1000);
                }

                timerChk(3);
            }
        });
    }, []);

    return (
        <div className="footerMenu">
            <button onClick={() => navigate("/main")} className={`footerMenuItem ${data.menuChk == 0 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 0 ? "footer_0_on.svg" : "footer_0_off.svg"}`}/>
                홈
            </button>
            <button onClick={() => navigate("/sada-dream")} className={`footerMenuItem ${data.menuChk == 1 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 1 ? "footer_1_on.svg" : "footer_1_off.svg"}`}/>
                사다드림
            </button>
            <button onClick={() => navigate("/dream-talk")} className={`footerMenuItem ${data.menuChk == 2 ? "active" : ""}`}>
                <div className="footerMenu_iconBox">
                    <img src={`/assets/images/icon/${data.menuChk == 2 ? "footer_2_on.svg" : "footer_2_off.svg"}`}/>
                    {countData ? <span className="countItem">{comFormat(countData)}</span> : ""}
                </div>
                드림톡
            </button>
            <button onClick={() => navigate("/mypage")} className={`footerMenuItem ${data.menuChk == 3 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 3 ? "footer_3_on.svg" : "footer_3_off.svg"}`}/>
                마이페이지
            </button>
        </div>
    );
}

export {FooterManu,FloatingMenuBox,FloatingMenuItem}