import { LoadingItem } from "component/basic/popup";
import { useEffect, useState } from "react";

function PageSizing(data){
    return (
        <div className={`pageSizing ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ContentsSection(data){
    const [headerShow,setHeaderShow] =  useState(false);
    const [reloadActive,setReloadActive] =  useState(false);
    const [reloadViewSize,setReloadViewSize] =  useState(0);
    const [reloadLoadingOpy,setReloadLoadingOpy] =  useState(0);
    const [loadChk,setLoadChk] =  useState(false);

    //새로고침
    const [startDownEvent,setStartDownEvent] =  useState(false);
    const [moveStert,setMoveStert] =  useState(false);
    const [moveType,setMoveType] =  useState("");
    const [startInfo,setStartInfo] =  useState({
        startX: -1,
        startY: -1,
        startTime: 0,
        start_point: 0
    });

    //초기화
    function initTouchInfo(){
        let startSet = {
            startX: -1,
            startY: -1,
            startTime: 0,
            start_point: 0
        }

        setStartInfo(startSet);
    }

    //downType 구분  
    function getMoveTypeDown(x, y){
        let reMoveType = {
            type : "up",
            y : 0
        };

        let nY = startInfo.startY - y;
        reMoveType.y = nY >= 0 ? 0 : nY;
        if(nY <= -150){
            reMoveType.type = "down"
        }

        return reMoveType;
    }

    function downTouchstart(e){
        if(!headerShow && loadChk){ 
            let startSet = {
                startX: e.touches[0].pageX,
                startY: e.touches[0].pageY,
                startTime: e.timeStamp,
                start_point: e.touches[0].pageY
            }

            setStartInfo(startSet);

            setStartDownEvent(true);
            setMoveStert(true);
        }
    }

    function downTouchmove(e){
        if(startDownEvent && !headerShow && loadChk){
            var moveX = e.touches[0].pageX;
            var moveY = e.touches[0].pageY;
            var moveDis = moveX + moveY;
            
            setMoveType(getMoveTypeDown(moveX, moveY));

            if(Math.abs(getMoveTypeDown(moveX, moveY).y) <= 80){
                setReloadLoadingOpy(Math.abs(getMoveTypeDown(moveX, moveY).y) / 80);

                if(Math.abs(getMoveTypeDown(moveX, moveY).y) == 0){
                    setReloadViewSize(0);
                }else{
                    setReloadViewSize(Math.abs(getMoveTypeDown(moveX, moveY).y));
                }
            }

            if(Math.abs(getMoveTypeDown(moveX, moveY).y) >= 80){
                setReloadActive(true);
                setReloadViewSize(80);
                setReloadLoadingOpy(1);
                initTouchInfo();
                setMoveType("");
                setMoveStert(false);
                window.location.reload(true);

                // setTimeout(() => {
                //     setReloadViewSize(0);
                //     setReloadLoadingOpy(0);
                //     window.scrollTo({left: 0, top: 0, behavior: "smooth"});

                //     setTimeout(() => {
                //         setReloadActive(false);
                //     }, 500);
                // }, 500);
            }
        }
    }

    function downTouchend(e){
        if(moveStert && moveType.y <= 80 && loadChk){
            setReloadActive(false);
            setReloadViewSize(0);
            setReloadLoadingOpy(0);
            initTouchInfo();
            setMoveType("");
            setMoveStert(false);
            window.scrollTo({left: 0, top: 0, behavior: "smooth"});
            
            setStartDownEvent(false);
        }
    }

    function handleScroll(){
        let scrollTop = document.documentElement.scrollTop;
  
        if(scrollTop > 0) {
            setHeaderShow(true);
        } else {
            setHeaderShow(false);
        }
    };

    useEffect(() => {
        handleScroll();

        setTimeout(() => {
            setLoadChk(true);
        }, 1000);
        
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`contentsSection ${data.showChk ? "showChkContents" : ""} ${reloadActive ? "reloadActive" : ""} ${data.headerChk ? data.showChk && headerShow ? "" : "headerContents" : ""} ${data.addClass ? data.addClass : ""}`}
            onTouchStart={(e)=>{if(data.loadChk)downTouchstart(e)}} onTouchMove={(e)=>{if(data.loadChk)downTouchmove(e)}} onTouchEnd={(e)=>{if(data.loadChk)downTouchend(e)}} style={data.loadChk ? {marginTop:`${reloadViewSize}px`} : {}}>
            <div className="reloadLoadingBox"><LoadingItem addClass={`reloadLoading ${reloadActive ? "active" : ""}`} style={{opacity:reloadLoadingOpy}}/></div>
            {data.children}
        </div>
    );
}

function ItemSection(data){
    return (
        <div className={`itemSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ItemSectionTitle(data){
    return (
        <div className="itemSectionTitleBox">
            <h1 className="itemSectionTitle" dangerouslySetInnerHTML={{__html:data.contents}}/>
            {data.subTitle ? <p className="itemSectionSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
        </div>
    );
}

function TextItem(data){
    return (
        <div className={`textItem ${data.addClass ? data.addClass : ""}`}>
            <p className="textItem_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="textItem_contents" dangerouslySetInnerHTML={{__html:data.contents}}/>
        </div>
    );
}

function PageTitle(data){
    return (
        <div className="pageTitleBox">
            <h1 className="pageTitle" dangerouslySetInnerHTML={{__html:data.contents}}/>
            {data.subTitle ? <p className="pageSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
            {data.subTitleBtn ? <button type="button" onClick={()=>{data.subTitleBtnFunc()}} className="pageSubTitleBtn" dangerouslySetInnerHTML={{__html:data.subTitleBtn}}/> : ""}
        </div>
    );
}

function InputSection(data){
    return (
        <div className={`input_section ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function LogoBox(data){
    return (
        <div className={`logoBox ${data.addClass ? data.addClass : ""}`}>
            <img src="/assets/images/app/logo.svg"/>
        </div>
    );
}

function InfoItem(data){
    return (
        <p className={`infoItem ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function CaptionItem(data){
    return (
        <p className={`captionItem ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function TimeSelBox(data){
    return (
        <div className={`timeSelBox ${data.addClass||""}`}>
            {data?.data?.map((item,i)=>(
                <button type="button" onClick={()=>{data.func(item.time)}} key={i} className={`timeSelItem ${data.value == item.time ? "active" : ""}`} disabled={item.is_reservation == 1 ? true : false}>{item.time}</button>
            ))}
        </div>
    );
}

function EditerBox(data){

    return (
        <div className="editerBox">
            {data.children}
        </div>
    );
}

function InfoTextBox(data){

    return (
        <div className="infoTextBox">
            <h1 className="infoText_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="infoText" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function InputViewBox(data){

    return (
        <div className={`inputViewBox ${data.type == "textArea" ? "textAreaViewBox" : ""}`}>
            <p className="inputViewText" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function FileViewBox(data){

    return (
        <div className={`fileViewBox`}>
            {data.data.map((item,i)=>(
                <div className="backgroundImg" key={i} style={{backgroundImage:`url('${item.image_url}')`}}/>
            ))}
        </div>
    );
}

function NameTextItemBox(data){

    return (
        <div className={`nameTextItemBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function NameTextItem(data){

    return (
        <div className={`nameTextItem ${data.addClass||""}`}>
            <p className="nameTextItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="nameTextItem_text" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function LinkItem(data){

    return (
        <div className={`linkItemBox ${data.addClass||""}`}>
            {data.data && data.data.map((item,i)=>(
                <button type="button" key={i} className="linkItem" onClick={()=>{item.func()}}>
                    {item.icon ? <img src={item.icon}/> : ""}
                    <p className="linkItem_name" dangerouslySetInnerHTML={{__html:item.name}}/>
                </button>
            ))}
        </div>
    );
}

export {PageSizing,ContentsSection,ItemSection,ItemSectionTitle,TextItem,LogoBox,PageTitle,InputSection,InfoItem,CaptionItem,TimeSelBox,EditerBox,InfoTextBox,InputViewBox,FileViewBox,NameTextItemBox,NameTextItem,LinkItem};