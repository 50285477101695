import { basicImgSet, comFormat, getDateGap } from "js/function";
import { useNavigate } from "react-router-dom";
import * as fatchSet from "../../api/api";
import { useEffect, useState } from "react";

function BrandSection(data){
    return (
        <div className={`brandSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function BrandBox(data){
    return (
        <div className={`brandBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function BrandItem(data){
    return (
        <button className={`brandItem ${data.nowVal === data.val ? "active" : ""} ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()}>
            <div className={`brandItemImg ${data.skeletonUI ? "skeletonUI" : ""}`}>
                <img src={data.img}/>
            </div>
            <span className={data.skeletonUI ? "skeletonUI textItem textItemCenter" : ""}>{data.text}</span>
        </button>
    );
}

function BtnAddProduct(data){
    return (
        <button className={`btnAddProduct ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()}>
            <div className={`btnAddProductTextBox`}>
                <h1 className="btnAddProductTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
                <h1 className="btnAddProductText" dangerouslySetInnerHTML={{__html:data.text}}/>
            </div>
            <img src="/assets/images/icon/move.svg"/>
        </button>
    );
}

function ProductCateSection(data){
    return (
        <div className={`productCateSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ProductCateItem(data){
    return (
        <button className={`productCateItem ${data.nowVal === data.val ? "active" : ""} ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()}>
            <div className={`productCateItemImg`}>
                <img src={data.img}/>
            </div>
            <span>{data.text}</span>
        </button>
    );
}

function ProductTypeItem(data){
    return (
        <button className={`productTypeItem ${data.activeType ? "active" : ""} ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()}>
            <img src={`/assets/images/icon/chk_${data.activeType ? "on" : "off"}.svg`}/>
            <span>{data.text}</span>
        </button>
    );
}

function SadadreamListItem_subInfo(data){
    const [like,setLike] = useState(data.likeType);
    const [likeCount,setLikeCount] = useState(data.text);
    const [submitChk,setSubmitChk] =  useState(true);

    function likeFunc(){
        if(submitChk){
            setSubmitChk(false);
            let formData = new FormData();
            formData.append("target_type", "sadadream");
            formData.append("target_id", data.id);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/like`,
                notStatusChk:true,
                success: (data) => {
                    setSubmitChk(true);
                    // data.func(like);
                    setLikeCount(like == 0 ? Number(likeCount) + 1 : likeCount == 0 ? 0 : Number(likeCount) - 1);
                    setLike(like == 0 ? 1 : 0);
                },
                err: (data) => {
                    setSubmitChk(true);
                    window.errPopupOpen(data);
                }
            })
        }
    }

    useEffect(()=>{
        setLike(data.likeType);
    },[data.likeType]);

    return (
        <button className={`sadadreamListItem_subInfo ${data.addClass ? data.addClass : ""} ${data.likeChk ? "likeItem" : ""}`} onClick={() => {
                if(data.likeChk){
                    likeFunc();
                }else{
                    data.func()
                }
            }}>
            {data.likeChk ? <img src={`/assets/images/icon/${like == 1 ? "like_on.svg" : "like_off.svg"}`}/> : <img src="/assets/images/icon/market.svg"/>}
            {likeCount || likeCount == 0 ? <span>{likeCount}</span> : ""}
        </button>
    );
}

function SadadreamListItemCover(data){
    return (
        <div className={`productTypeItem_cover ${data.addClass ? data.addClass : ""}`}>
            <span>{data.text}</span>
        </div>
    );
}

function SadadreamListItemMarket(data){
    return (
        <div className="sadadreamListItem_market">
            <div className="sadadreamListItem_marketImg backgroundImg" style={{backgroundImage:`url('${basicImgSet(data.user_profile_url)}')`}}></div>
            {data.user_nickname}
        </div>
    );
}

function SadadreamListItemLabel(data){
    return (
        <div className={`sadadreamListItem_label ${data.addClass||""}`}>
            <span>{data.text}</span>
        </div>
    );
}

function SadadreamListItem(data){
    let navigate = useNavigate();
    let setDate = data.shopping_due_date ? getDateGap(null,data.shopping_due_date,"day","todayChk") : 0;
    let addr = data.sadadream_product_direct_dealing_places ? data.sadadream_product_direct_dealing_places.split(" ")[1] ? data.sadadream_product_direct_dealing_places.split(" ")[1] : data.sadadream_product_direct_dealing_places.split(" ")[0] : null;
    
    return (
        <div className={`sadadreamListItem ${data.listType ? data.listType : ""}`}>
            <button type="button" onClick={()=>{if(!data.skeletonUI)navigate(`/sada-dream/detail/${data.id}`)}} className={`sadadreamListItem_img backgroundImg ${data.skeletonUI ? "skeletonUI" : ""}`} style={{backgroundImage:`url('${data.image_url}')`}}>
                {data.amount <= 0 && !data.skeletonUI ? 
                    <SadadreamListItemCover text="거래 완료"/>
                    :
                    data.status == 11 || setDate < 0 ?
                    <SadadreamListItemCover addClass="dColor" text="마감 상품"/>
                :""}
                {data.listType && !data.skeletonUI ? <SadadreamListItemLabel addClass={data.type == "request" ? "oColor" : ""} text={data.type == "request" ? "사다주세요" : "사다드려요"}/> : ""}
            </button>
            <div className="sadadreamListItem_contetns">
                <button type="button" onClick={()=>{if(!data.skeletonUI)navigate(`/sada-dream/detail/${data.id}`)}} className="sadadreamListItem_info">
                    {data.listType && !data.skeletonUI ? 
                        <SadadreamListItemMarket user_profile_url={data.user_profile_url} user_nickname={data.user_nickname}/>
                        :
                        <p className={`sadadreamListItem_date ${data.skeletonUI ? "skeletonUI textItem textItemFull" : ""}`}>{!data.skeletonUI ? <><img src="/assets/images/icon/cel.svg"/>{data.status == 11 ? "마감" : setDate == 0 ? "D-Day" : setDate > 0 ? `D-${setDate}` : "마감"}</> : ""}</p>
                    } 
                    <h1 className={`sadadreamListItem_name ${data.skeletonUI ? "skeletonUI textItem textItemFull" : ""}`}>{data.name}</h1>
                    <div className={`sadadreamListItem_priceBox ${data.skeletonUI ? "skeletonUI textItem textItemFull" : ""}`}>
                        {data.price || data.price == 0 ? <h2 className={`sadadreamListItem_price ${data.type == "request" ? "oTextColor" : ""}`}>{comFormat(data.price)}원</h2> : ""}
                        {data.dream_fee || data.dream_fee == 0 ? <h3 className="sadadreamListItem_dreamFee">드림비 {comFormat(data.dream_fee)}원</h3> : ""}
                    </div>
                    {!data.skeletonUI ? 
                    <>
                        {data.listType ? 
                            <div className="sadadreamListItem_subInfo_v2">
                                <div className="sadadreamListItem_subInfo_v2_info">
                                    {data.use_delivery == 1 || data.use_direct_dealing == 1 ?
                                    <span>
                                        {data.use_delivery == 1 && data.use_direct_dealing == 1 ? "택배/직거래" : data.use_delivery == 1 ? "택배" : data.use_direct_dealing == 1 ? "직거래" : ""}
                                    </span>
                                    :""}
                                    {addr ? 
                                    <span>
                                        {addr}
                                    </span>
                                    :""}
                                    <span>
                                        {data.shopping_place}
                                    </span>
                                </div>
                                <img src="/assets/images/icon/market.svg"/>
                            </div>
                        :
                            <SadadreamListItemMarket user_profile_url={data.user_profile_url} user_nickname={data.user_nickname}/>
                        }
                    </>
                    :""}
                </button>
                <div className="sadadreamListItem_subInfoBox">
                    {!data.skeletonUI ? 
                        <>
                            {data.listType ? "" :
                                <SadadreamListItem_subInfo
                                    id={data.id}
                                    text={data.shopping_place}
                                    func={()=>{navigate(`/sada-dream/detail/${data.id}`)}}
                                />
                            }
                            <SadadreamListItem_subInfo
                                id={data.id}
                                text={data.listType ? null : comFormat(data.total_like_count)}
                                likeChk={true}
                                likeType={data.my_like}
                                func={(e)=>{}}
                            />
                        </>
                    :
                        <>
                            <p className="skeletonUI textItem"></p>
                            <p className="skeletonUI textItem"></p>
                        </>
                    }
                </div>                
            </div>
        </div>
    );
}

export {BrandItem,BrandBox,BrandSection,BtnAddProduct,ProductCateSection,ProductCateItem,ProductTypeItem,SadadreamListItem}