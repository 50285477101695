import { useEffect, useState } from "react";

//tap item
function Tap(data){
    const [headerShow,setHeaderShow] =  useState(false);

    function handleScroll(){
        let scrollTop = document.documentElement.scrollTop;
  
        if(scrollTop > 0) {
            setHeaderShow(true);
        } else {
            setHeaderShow(false);
        }
    };

    useEffect(() => {
        handleScroll();
        
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`tapBox ${data.showChk ? "showChkTap" : ""} ${data.headerCom ? data.showChk && headerShow ? "fixedTap" : "headerComTap" : ""} ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`tapItem ${item.id == data.val ? "active" : ""}`}>{item.category}</button>
            ))}
        </div>
    );
}

function SubTap(data){
    return (
        <div className={`subTapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`subTapItem ${item.id == data.val ? "active" : ""}`}>{item.category}</button>
            ))}
        </div>
    );
}

function SubLine(data){
    return (
        <div className={`subLine ${data.addClass ? data.addClass : ""}`} style={{margin:data.margin}}></div>
    );
}

function PopupTap(data){
    return (
        <div className={`popupTapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`popupTapItem ${item.id == data.val ? "active" : ""}`}>{item.category}</button>
            ))}
        </div>
    );
}

function ImgTap(data){
    return (
        <div className={`imgTapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`imgTapItem ${item.id == data.val ? "active" : ""}`}>
                    <img src={item.id == data.val ? item.activeImg : item.img}/>
                </button>
            ))}
        </div>
    );
}

export {Tap, SubTap, SubLine, PopupTap, ImgTap}