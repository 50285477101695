import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FooterManu } from "component/elements/footer";

const Main = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        // if(localStorage.getItem("token")){
        //     navigate("/sada-dream");
        // }else{
        //     // navigate("/member/login");
        //     navigate("/sada-dream");
        // }
    }, []);

    return (
        <FooterManu menuChk="0"/>
    );
};

export default Main;