import { useEffect } from "react";
import { useState } from "react";

//category item
function CategoryBox(data){
    const [value,setValue] = useState(data.val);

    useEffect(() => {
        setValue(data.val);
    }, [data.val]);

    return (
        <div className={`categoryBox ${data.addClass ? data.addClass : ""}`}>
            {data.allBtn ? 
                <button type="button" onClick={() => data.func("")} className={`categoryItem ${"" == value ? "active" : ""}`}>전체</button>
            :""}
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`categoryItem ${item.id == value ? "active" : ""}`}>{item.category_name}</button>
            ))}
        </div>
    );
}

function CategorySelBox(data){
    return (
        <button type="button" onClick={() => data.func()} className={`btn_categorySel ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.val}}/>
    );
}

export {CategoryBox,CategorySelBox};