import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SelPopup, ToastPopup } from "component/basic/popup";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { FloatingMenuBox, FloatingMenuItem, FooterManu } from "component/elements/footer";
import { ImgTap, Tap } from "component/basic/tap";
import { arrChunk, bottomChk, scrollx_mouse } from "js/function";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import { BrandBox, BrandItem, BrandSection, BtnAddProduct, ProductCateItem, ProductCateSection, ProductTypeItem, SadadreamListItem } from "component/sadadream/List";
import { CategorySelBox } from "component/basic/category";

const SadadreamList = (props) => {
    const navigate = useNavigate();
    const [skeletonUI,setSkeletonUI] =  useState(true);
    const [skeletonUIList,setSkeletonUIList] =  useState(true);
    const [listFormData,setListFormData] =  useState({
        page: 1,
        productType: {inclusion_close:0,inclusion_sold_out:1},
        align: {id:"newest",text:"최신 순"},
        type: "",
        brand: null,
        category: {id:null,text:"카테고리"},
        subCategory: {id:null,text:"하위 카테고리"}
    });

    let lastPage = false;
    let settingChk = 0;

    const [itemList,setItemList] =  useState([]);
    const listSkeleton = [0,1,2,3];

    const scrollItem = useRef(null);

    const [brandList,setBrandList] =  useState([]);
    const brandSkeleton = [0,1,2,3,4,5,6,7];

    const [listType,setListType] =  useState(null);

    const [inclusionClose,setInclusionClose] =  useState(listFormData?.productType?.inclusion_close);
    const [inclusionSoldOut,setInclusionSoldOut] =  useState(listFormData?.productType?.inclusion_sold_out);

    const [subCategoryText,setSubCategoryText] =  useState([]);
    const [subCategoryVal,setSubCategoryVal] =  useState([]);

    const brandApi = useGet({
        url:`/brand`,
        loginType:"login",
        notStatusChk:true
    });
    const brandData = brandApi?.data?.brands;

    const categoryApi = useGet({
        url:`/product/category/main`,
        loginType:"login",
        notStatusChk:true
    });
    const categoryData = categoryApi?.data?.product_main_categories;

    const subCategoryApi = useGet({
        url:listFormData?.category.id ? `/product/category/sub?product_main_category_id=${listFormData?.category.id}` : null,
        loginType:"login",
        notStatusChk:true
    });
    const subCategoryData = subCategoryApi?.data?.product_sub_categories;

    const [toastPopupData,setToastPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);

    const productApi = useGet({
        url:`/sadadream?page=${listFormData?.page}&inclusion_close=${listFormData?.productType?.inclusion_close}&inclusion_sold_out=${listFormData?.productType?.inclusion_sold_out}&order=${listFormData?.align?.id == null ? "" : listFormData?.align?.id}&type=${listFormData?.type}&brand_id=${listFormData?.brand == null ? "" : listFormData?.brand}&product_main_category_id=${listFormData?.category?.id == null ? "" : listFormData?.category?.id}${listFormData?.subCategory?.id||listFormData?.subCategory?.id == 0 ? `&product_sub_category_id[]=${listFormData?.subCategory?.id}` : ""}&is_random=1`,
        loginType:"login",
        notStatusChk:true
    });
    const productListData = productApi?.data?.sadadream_products;

    const newProductApi = useGet({
        url:listFormData?.align?.id == "newest" ? `/sadadream/new?page=1&inclusion_close=${listFormData?.productType?.inclusion_close}&inclusion_sold_out=${listFormData?.productType?.inclusion_sold_out}&order=${listFormData?.align?.id == null ? "" : listFormData?.align?.id}&type=${listFormData?.type}&brand_id=${listFormData?.brand == null ? "" : listFormData?.brand}&product_main_category_id=${listFormData?.category?.id == null ? "" : listFormData?.category?.id}${listFormData?.subCategory?.id||listFormData?.subCategory?.id == 0 ? `&product_sub_category_id[]=${listFormData?.subCategory?.id}` : ""}&is_random=1` : null,
        loginType:"login",
        notStatusChk:true
    });
    const newProductListData = listFormData?.align?.id == "newest" ? newProductApi?.data?.new_sadadream_products : null;

    function toastPopupClose(){
        setTimeout(() => {
            setToastPopupData(null);
        }, 200);
    }

    useEffect(() => {
        if(brandData){
            setBrandList(arrChunk(brandData,8));

            setSkeletonUI(false);
        }
    }, [brandApi]);

    useEffect(() => {
        if(subCategoryData){
            let textArr = [],
                valArr = [];
            subCategoryData.forEach(function(item,i){
                textArr.push(item.category);
                valArr.push(item.id);

                if(subCategoryData.length - 1 == i){
                    setSubCategoryText(textArr);
                    setSubCategoryVal(valArr);
                }
            })
        }
    }, [subCategoryApi]);

    function backSetting(){
        let settingData = JSON.parse(sessionStorage.getItem("scrollData"));
        let settingScroll = sessionStorage.getItem("scrollPoint");

        setListFormData(settingData?.listFormData);
        setListType(settingData?.listType);
        setItemList(settingData?.itemList);
        setTimeout(() => {
            window.scrollTo(0, settingScroll);
        }, 1000);
        setTimeout(() => {
            sessionStorage.removeItem("backChk");
        }, 3000);
    }

    useEffect(() => {
        window.onpopstate = () => {
            sessionStorage.setItem("backChk",true);
        }
    }, []);

    //마우스 좌우 스크롤
    useEffect(()=>{
        if(scrollItem.current){
            scrollx_mouse(scrollItem.current)
        }
    },[scrollItem.current]);

    function addPage(){
        let newData = {...listFormData};
        window.loadingBox(true);
        newData.page = newData.page + 1;
        setListFormData(newData);

        settingChk = 1;
        setTimeout(() => {
            settingChk = 0;
        }, 300);

        setTimeout(() => {
            window.loadingBox(false);
        }, 10000);
    };

    useEffect(() => {
        if(sessionStorage.getItem("backChk")){
            backSetting();
        }else{
            if(productListData?.data && productListData?.data.length > 0){
                if(listFormData?.page == 1){
                    setItemList([...productListData?.data]);
                }else{    
                    setItemList(prev => [...prev, ...productListData?.data]);
                }
            }else{
                setItemList([]);
            }
        }

        lastPage = productListData?.current_page < productListData?.last_page;

        setTimeout(() => {
            window.loadingBox(false);
        }, 200);
        

        window.removeEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [productApi]);

    function handleScroll(){
        let scrollTop = document.documentElement.scrollTop;
        if(!sessionStorage.getItem("backChk"))sessionStorage.setItem("scrollPoint",scrollTop);

        if(bottomChk() && lastPage && settingChk == 0){
            addPage();
        }
    };

    useEffect(() => {
        if(!sessionStorage.getItem("backChk")){setItemList([])};
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        setSkeletonUI(true);
        setSkeletonUIList(true);
        window.loadingBox(true);
        setTimeout(() => {
            window.loadingBox(false);
        }, 1000);
    }, []);

    useEffect(() => {
        if(productListData){
            setSkeletonUIList(false);
        }
    }, [productApi]);

    useEffect(() => {
        window.loadingBox(true);

        setTimeout(() => {
            window.loadingBox(false);
        }, 3000);
    }, [listFormData]);

    useEffect(() => {
        if(!sessionStorage.getItem("backChk")){
            sessionStorage.setItem("scrollData",JSON.stringify({
                listFormData: listFormData,
                listType: listType,
                itemList:itemList
            }));
        }
    }, [listFormData,listType,itemList]);

    return (
        <>
            <Header
                alarmBtn={true}
                shLink={true}
                showChk={true}
            />
            <Tap
                addClass={`${listFormData?.type == "request" ? "oColor" : ""}`}
                headerCom={true}
                showChk={true}
                func={(id)=>{
                    let newData = {...listFormData};
                    newData.page = 1;
                    newData.type = id;
                    setListFormData(newData);
                }}
                val={listFormData?.type}
                data={[
                    {id:"",category:"전체"},
                    {id:"recruit",category:"사다드려요"},
                    {id:"request",category:"사다주세요"}
                ]}
            />
            <ContentsSection loadChk={true} showChk={true} headerChk={true} addClass="headerTapContents footerContents">
                {skeletonUI ? 
                    <BrandSection>
                        <BrandBox>
                            {brandSkeleton?.map((item,i)=>(
                                <BrandItem key={i} skeletonUI={true}/>
                            ))}
                        </BrandBox>
                    </BrandSection>
                :
                    <BrandSection>
                        {brandList?.length > 0 ?
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={1}
                                pagination={true}
                                loop={true}
                                onRealIndexChange={(e) => {}}
                                modules={[Pagination]}
                            >
                                {brandList && brandList?.map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <BrandBox>
                                            {item?.map((subItem,idx)=>(
                                                <BrandItem
                                                    key={idx}
                                                    nowVal={listFormData?.brand}
                                                    val={subItem.id}
                                                    func={()=>{
                                                        let newData = {...listFormData};
                                                        newData.page = 1;
                                                        if(listFormData?.brand === subItem.id){
                                                            newData.brand = null;
                                                        }else{
                                                            newData.brand = subItem.id;
                                                        }
                                                        setListFormData(newData);
                                                    }}
                                                    img={subItem.image_url}
                                                    text={subItem.name}
                                                />
                                            ))}
                                        </BrandBox>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        :""}
                    </BrandSection>
                }
                <div className="listCategory_section">
                    <div className="listCategory_area scrollCoverBox" ref={scrollItem}>
                        <CategorySelBox
                            addClass=""
                            func={() => setSelPopupData({
                                title:"정렬 순서",
                                addClass:null,
                                closeType:true,
                                items:["최신 순","찜 많은 순","조회 높은 순","가격 낮은 순","가격 높은 순"],
                                val:["newest","popular","view","price_low","price_high"],
                                sel:listFormData?.align?.id,
                                closeFunc:(e) => {
                                    setTimeout(() => {
                                        setSelPopupData(null);
                                    }, 200);
                                },
                                func:(e) => {
                                    let newData = {...listFormData};
                                    newData.page = 1;
                                    newData.align = {id:e.val,text:e.text};
                                    setListFormData(newData);

                                    setTimeout(() => {
                                        setSelPopupData(null);
                                    }, 200);
                                }
                            })}
                            val={listFormData?.align?.text}
                        />
                        <CategorySelBox
                            addClass=""
                            func={()=>{
                                setToastPopupData({
                                    addClass:"",
                                    closeType:true,
                                    closeFuncChk:true,
                                    closeFunc:(e)=>{
                                        toastPopupClose();
                                    },
                                    title:"상품 카테고리",
                                    textBtn:"취소",
                                    textBtnFunc:(e)=>{
                                        toastPopupClose();
                                    }
                                })
                            }}
                            val={listFormData?.category?.text}
                        />
                        {listFormData?.category?.id ? 
                            <CategorySelBox
                                addClass=""
                                func={()=>{
                                    setSelPopupData({
                                        title:"하위 카테고리",
                                        addClass:null,
                                        closeType:true,
                                        items:subCategoryText,
                                        val:subCategoryVal,
                                        sel:listFormData?.subCategory?.id,
                                        closeFunc:(e) => {
                                            setTimeout(() => {
                                                setSelPopupData(null);
                                            }, 200);
                                        },
                                        func:(e) => {
                                            let newData = {...listFormData};
                                            newData.page = 1;
                                            newData.subCategory = {id:e.val,text:e.text};
                                            setListFormData(newData);

                                            setTimeout(() => {
                                                setSelPopupData(null);
                                            }, 200);
                                        }
                                    })
                                }}
                                val={listFormData?.subCategory?.text}
                            />
                        :""}
                        <CategorySelBox
                            addClass=""
                            func={()=>{
                                setToastPopupData({
                                    addClass:"",
                                    closeType:true,
                                    closeFuncChk:true,
                                    closeFunc:(e)=>{
                                        setInclusionClose(listFormData?.productType?.inclusion_close);
                                        setInclusionSoldOut(listFormData?.productType?.inclusion_sold_out);
                                        toastPopupClose();
                                    },
                                    title:"마감/거래완료",
                                    textBtn:"적용",
                                    textBtnFunc:(e)=>{
                                        let newData = {...listFormData};
                                        newData.productType = {inclusion_close:inclusionClose,inclusion_sold_out:inclusionSoldOut};
                                        newData.page = 1;
                                        setListFormData(newData);

                                        toastPopupClose();
                                    }
                                })
                            }}
                            val={`${listFormData?.productType?.inclusion_close ? "마감" : ""}${listFormData?.productType?.inclusion_close && listFormData?.productType?.inclusion_sold_out ? " <span class='textSideLine'></span> " : ""}${listFormData?.productType?.inclusion_sold_out ? "거래완료" : ""} ${listFormData?.productType?.inclusion_close || listFormData?.productType?.inclusion_sold_out ? "포함" : "마감 <span class='textSideLine'></span> 거래완료 미포함"}`}
                        />
                    </div>
                    <ImgTap
                        addClass={""}
                        func={(id)=>{
                            setListType(id);
                        }}
                        val={listType}
                        data={[
                            {id:null,activeImg:"/assets/images/icon/list_type_0_on.svg",img:"/assets/images/icon/list_type_0_off.svg"},
                            {id:"col2",activeImg:"/assets/images/icon/list_type_1_on.svg",img:"/assets/images/icon/list_type_1_off.svg"}
                        ]}
                    />
                </div>
                <div className={`productList ${listType ? listType : ""}`}>
                    {skeletonUIList ? 
                        <>
                            {listSkeleton?.map((item,i)=>(
                                <SadadreamListItem key={i} listType={listType} skeletonUI={true}/>
                            ))}
                        </>
                    :
                        <>
                        {newProductListData && newProductListData.length > 0 ? 
                        <>
                            {newProductListData?.map((item, i) => (
                                <SadadreamListItem
                                    listType={listType}
                                    key={i}
                                    id={item.id}
                                    status={item.status}
                                    amount={item.amount}
                                    image_url={item.image_url}
                                    shopping_due_date={item.shopping_due_date}
                                    name={item.name}
                                    type={item.type}
                                    price={item.price}
                                    dream_fee={item.dream_fee}
                                    user_profile_url={item.user_profile_url}
                                    user_nickname={item.user_nickname}
                                    shopping_place={item.shopping_place}
                                    total_like_count={item.total_like_count}
                                    my_like={item.my_like}
                                    use_direct_dealing={item.use_direct_dealing}
                                    use_delivery={item.use_delivery}
                                    sadadream_product_direct_dealing_places={item.sadadream_product_direct_dealing_places ? item.sadadream_product_direct_dealing_places[0]?.address : null}
                                />
                            ))}
                        </>
                        :""}
                        {itemList && itemList.length > 0 ? 
                        <>
                            {itemList?.map((item, i) => (
                                <SadadreamListItem
                                    listType={listType}
                                    key={i}
                                    id={item.id}
                                    status={item.status}
                                    amount={item.amount}
                                    image_url={item.image_url}
                                    shopping_due_date={item.shopping_due_date}
                                    name={item.name}
                                    type={item.type}
                                    price={item.price}
                                    dream_fee={item.dream_fee}
                                    user_profile_url={item.user_profile_url}
                                    user_nickname={item.user_nickname}
                                    shopping_place={item.shopping_place}
                                    total_like_count={item.total_like_count}
                                    my_like={item.my_like}
                                    use_direct_dealing={item.use_direct_dealing}
                                    use_delivery={item.use_delivery}
                                    sadadream_product_direct_dealing_places={item.sadadream_product_direct_dealing_places ? item.sadadream_product_direct_dealing_places[0]?.address : null}
                                />
                            ))}
                        </>
                        :""}
                    </>}
                </div>
                <FloatingMenuBox addClass="footerChk">
                    <FloatingMenuItem
                        type="up"
                        bgColor="#F1F2F5"
                        img="/assets/images/icon/up_scroll.svg"
                    />
                    <FloatingMenuItem
                        func={()=>{
                            setToastPopupData({
                                addClass:"",
                                closeType:true,
                                closeFuncChk:true,
                                closeFunc:(e)=>{
                                    toastPopupClose();
                                },
                                title:"상품등록",
                                textBtn:"취소",
                                textBtnFunc:(e)=>{
                                    toastPopupClose();
                                }
                            })
                        }}
                        bgColor="#58A3EF"
                        img="/assets/images/icon/add_link_icon.svg"
                    />
                </FloatingMenuBox>
                <FooterManu menuChk="1"/>
            </ContentsSection>
            <SelPopup
                data={selPopupData}
            />
            <ToastPopup data={toastPopupData}>
                {toastPopupData?.title == "상품등록" ? 
                <>
                    <BtnAddProduct
                        addClass=""
                        func={()=>{toastPopupClose();navigate("/post/send")}}
                        title="사다드려요"
                        text="상품을 업로드하고 구매 신청을 받아보세요."
                    />
                    <BtnAddProduct
                        addClass=""
                        func={()=>{toastPopupClose();navigate("/post/bring")}}
                        title="사다주세요"
                        text="필요한 상품을 업로드하고 사다줄 사람을 찾아보세요."
                    />
                </>
                : toastPopupData?.title == "상품 카테고리" ? 
                <ProductCateSection>
                    {categoryData && categoryData?.map((item,i)=>(
                        <ProductCateItem
                            key={i}
                            nowVal={listFormData?.category.id}
                            val={item.id}
                            func={()=>{
                                let newData = {...listFormData};

                                if(listFormData?.category.id === item.id){
                                    newData.category = {id:null,text:"카테고리"};
                                }else{
                                    newData.category = {id:item.id,text:item.category};
                                }
                                newData.page = 1;
                                setListFormData(newData);

                                toastPopupClose();
                            }}
                            img={item.image_url}
                            text={item.category}
                        />
                    ))}
                </ProductCateSection>
                : toastPopupData?.title == "마감/거래완료" ? 
                    <>
                        <ProductTypeItem
                            activeType={inclusionClose}
                            func={()=>{
                                setInclusionClose(inclusionClose ? 0 : 1);

                                let popupDataSet = {...toastPopupData};
                                popupDataSet.textBtnFunc = (e)=>{
                                    let newData = {...listFormData};
                                    newData.productType = {inclusion_close:inclusionClose ? 0 : 1,inclusion_sold_out:inclusionSoldOut};
                                    newData.page = 1;
                                    setListFormData(newData);

                                    toastPopupClose();
                                }
                                setToastPopupData(popupDataSet);
                            }}
                            text={"마감 상품 포함"}
                        />
                        <ProductTypeItem
                            activeType={inclusionSoldOut}
                            func={()=>{
                                setInclusionSoldOut(inclusionSoldOut ? 0 : 1);

                                let popupDataSet = {...toastPopupData};
                                popupDataSet.textBtnFunc = (e)=>{
                                    let newData = {...listFormData};
                                    newData.productType = {inclusion_close:inclusionClose,inclusion_sold_out:inclusionSoldOut ? 0 : 1};
                                    newData.page = 1;
                                    setListFormData(newData);

                                    toastPopupClose();
                                }
                                setToastPopupData(popupDataSet);
                            }}
                            text={"거래 완료 상품 포함"}
                        />
                    </>
                :""}
            </ToastPopup>
        </>
    );
};

export default SadadreamList;