import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGet from "api/useGet";

function Header(headerData){
    let navigate = useNavigate();
    const apiChkval = useRef(null);
    const randomChkval = useRef(null);
    const [updateApi,setUpdateApi] =  useState(0);
    const [placeholder,setPlaceholder] =  useState("");
    const [headerShow,setHeaderShow] =  useState(false);

    const headerApi = useGet({
        url:headerData?.alarmBtn || headerData?.cart ? `/header?update=${updateApi}` : null,
        loginType:"login"
    });
    const alarmData = headerApi?.data?.alarm_count;
    const cartData = headerApi?.data?.cart_count;

    const mainApi = useGet({
        url:headerData?.shLink ? `/main` : null,
        loginType:"login"
    });
    const placeholderData = mainApi?.data?.random_placeholders;

    useEffect(() => {
        if(placeholderData){
            setPlaceholder(placeholderData[0]?.text);

            if (randomChkval.current !== null) {
                clearInterval(randomChkval.current);
                randomChkval.current = null;
            }
        
            randomChkval.current = setInterval(function() {
                let random_idx = Math.floor(Math.random() * placeholderData.length);
                let random_val = placeholderData[random_idx];

                setPlaceholder(random_val?.text||"");
            }, 3000);
        }

        return () => {
            if (randomChkval.current !== null) {
                clearInterval(randomChkval.current);
                randomChkval.current = null;
            }
        };
    }, [mainApi]);

    useEffect(() => {
        if (apiChkval.current !== null) {
            clearInterval(apiChkval.current);
            apiChkval.current = null;
        }
    
        let upDateItem = 0;
        apiChkval.current = setInterval(function() {
            setUpdateApi(upDateItem++)
        }, 30000);

        return () => {
            if (apiChkval.current !== null) {
                clearInterval(apiChkval.current);
                apiChkval.current = null;
            }
        };
    }, []);

    function handleScroll(){
        let scrollTop = document.documentElement.scrollTop;
  
        if(scrollTop > 0) {
            setHeaderShow(true);
        } else {
            setHeaderShow(false);
        }
    };

    useEffect(() => {
        handleScroll();

        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`header ${headerShow && headerData.showChk ? "hide" : ""}`}>
            <div className={`headerSection ${headerData.addClass ? headerData.addClass : ""}`}>
                {!headerData.pageBack || headerData.pageBack === "" ? "" : <button type="button" className="btnBack btnHeaderLeft" onClick={(e) => headerData.func(e)}><img src="/assets/images/basic/back.svg"/></button>}
                {headerData.alarmBtn ? <button type="button" className={`btnAlarmLink btnHeaderLeft ${headerData.alarmData !== 0 ? "active" : ""}`} onClick={(e) => {navigate("/alarm")}}><div className="btnAlarmLinkItem"><img src={`/assets/images/icon/alarm.svg`}/></div></button> : ""}
                {headerData.logo ? <button type="button" className="btnLogo btnHeaderLeft" onClick={(e) => navigate("/")}><img src="/assets/images/app/logo_min.svg"/></button> : ""}
                {headerData.shLink ? 
                    <button type="button" className="btnShLink btnHeaderRight" onClick={(e) => navigate("/search-sada")}>
                        <span>{placeholder}</span>
                        <img src="/assets/images/icon/sh_icon.svg"/>
                    </button>
                 : ""}
                {headerData.carNumber ? <div className="headCarNumberBox btnHeaderLeft"><p className="headCarNumber">{headerData.carNumber}</p></div> : ""}
                {headerData.headTitle ? <p className={`headTitle ${headerData.centerTitle ? "centerTitle" : ""} ${headerData.titleClass ? headerData.titleClass : ""}`} dangerouslySetInnerHTML={{__html:headerData.headTitle}}/> : ""}
                {headerData.rightBtns ? 
                    <div className="headerInfoBox btnHeaderRight">
                        {headerData.rightBtnsArr.map((item, i) => (
                            <Fragment key={i}>
                                {item.type !== "img" ?
                                <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}} dangerouslySetInnerHTML={{__html:item.contents}}></button>
                                :
                                <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}}><img src={item.contents}/></button>
                                }
                            </Fragment>
                        ))}
                    </div>
                    : ""
                }
                {headerData.homeBtn ? <button type="button" className="btnHomeLink btnHeaderRight" onClick={(e) => {navigate("/")}}><img src="/assets/images/icon/home_icon.svg"/></button> : ""}
                {headerData.pageClose ? <button type="button" className="btnClose btnHeaderRight" onClick={(e) => {headerData.closeFunc(e)}}><img src="/assets/images/basic/close_w.svg"/></button> : ""}
                {headerData.children}
            </div>
        </div>
    );
}

export {Header};